
import { PopoutsModule } from "@/store/modules/popouts";
import CustomButton from "@/components/CustomButton.vue";
import { defineComponent } from "vue";
import { MessagesModule } from "@/store/modules/messages";
import { deleteMessages } from "@/services/messagesService";
export default defineComponent({
  name: "DeleteMessageBatch",
  components: {
    CustomButton,
  },
  data() {
    return {
      requestSent: false,
    };
  },
  computed: {
    selectedMessageIds() {
      return MessagesModule.selectedMessageIds;
    },
    channelId(): string {
      return this.$route.params.channel_id as string;
    },
  },
  methods: {
    close() {
      PopoutsModule.ClosePopout("delete-message-batch");
    },
    backgroundClick(event: any) {
      if (event.target.classList.contains("popout-background")) {
        this.close();
      }
    },
    async deleteMessages() {
      if (this.requestSent) return;
      this.requestSent = true;
      deleteMessages(this.channelId, this.selectedMessageIds).finally(() => {
        this.requestSent = false;
        MessagesModule.deselectAll();
        this.close();
      });
    },
  },
});
